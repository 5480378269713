<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on"> {{ icon }} </v-icon>
    </template>
    <span> {{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["idTipoMeta", "text"],

  data() {
    return {
      icons: {
        2: "mdi-account",
        3: "mdi-account-multiple",
        4: "mdi-domain",
        5: "mdi-chart-line-variant",
        6: "mdi-account-group",
        7: "mdi-account-multiple-plus",
        10: "mdi-motorbike",
        21: "mdi-account-multiple-check",
        41: "mdi-cart",
        61: "mdi-cogs"
      }
    };
  },

  computed: {
    icon() {
      return this.icons[this.idTipoMeta];
    }
  }
};
</script>

<style></style>
